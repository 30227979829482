<script>
import { defineComponent } from 'vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'

export default defineComponent({
  extends: BaseCheckbox,
})
</script>

<template>
  <label
    :class="[
      'relative z-0 flex cursor-pointer outline-none',
      disabled && 'pointer-events-none cursor-default',
    ]"
    :tabindex="disabled ? -1 : tabindex"
    @keydown.space.prevent="onSpaceDown"
  >
    <input
      ref="input"
      v-model="checkedModel"
      :disabled="disabled"
      :value="value"
      :name="name"
      class="hidden outline-none"
      type="checkbox"
      @change="change($event)"
    />
    <div
      class="mt-0.5 flex h-4 w-4 shrink-0 items-center justify-center rounded border text-transparent text-white transition-all duration-100 ease-linear hover:border-primary focus:border-primary"
      :class="[
        !disabled && checkedValue && 'border-primary bg-primary',
        disabled && 'border-disabled',
        disabled && checkedValue && 'bg-disabled',
      ]"
    >
      <base-icon svg="plain/check" :size="9" />
    </div>
    <div
      v-if="!hideLabel"
      class="ml-2.5 text-sm font-medium leading-5"
      :class="[disabled && 'text-fg-disabled']"
    >
      <slot />
    </div>
  </label>
</template>
