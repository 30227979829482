<script>
import Vue, { defineComponent, computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseToggle from '/~/components/base/toggle/base-toggle.vue'
import { useCommunicationPreferences, useUser } from '/~/composables/user'
import BaseCheckbox from '/~/templates/australiapost/components/base/checkbox/base-checkbox.vue'

export default defineComponent({
  components: {
    BaseAsidePage,
    BaseButton,
    BaseCheckbox,
    BaseLoader,
    BaseToggle,
  },
  setup() {
    const {
      isCommunicationPreferencesLoading,
      hasNoCommunicationPreferences,
      smsPreferences,
      emailPreferences,
      hasSmsPreferences,
      hasEmailPreferences,
      isCommunicationPreferencesChanged,
      fetchCommunicationPreferences,
      saveCommunicationPreferences,
    } = useCommunicationPreferences()
    const { user } = useUser()

    const inputs = computed(() => {
      const inputs = []

      if (hasSmsPreferences.value) {
        inputs.push({
          label: 'SMS communications',
          type: 'toggle',
          title: 'Mobile',
          description: user.value.mobile,
          preference: smsPreferences.value[0],
        })
      }

      if (hasEmailPreferences.value) {
        inputs.push({
          label: 'Email communications',
          type: 'checkbox-list',
          description: 'Select updates you wish to receive',
          list: emailPreferences.value,
        })
      }

      return inputs
    })

    async function save() {
      try {
        await saveCommunicationPreferences()

        Vue.notify({
          type: 'success',
          text: 'Communication preferences updated successfully',
        })
      } catch (error) {
        Vue.notify({
          type: 'error',
          text: error.message,
        })
      }
    }

    if (hasNoCommunicationPreferences.value) {
      fetchCommunicationPreferences()
    }

    return {
      inputs,
      isCommunicationPreferencesLoading,
      hasNoCommunicationPreferences,
      isCommunicationPreferencesChanged,
      save,
    }
  },
})
</script>

<template>
  <base-aside-page title="Communication Preferences">
    <base-loader
      v-if="isCommunicationPreferencesLoading && hasNoCommunicationPreferences"
      class="mt-5"
    />
    <div v-else class="flex grow flex-col">
      <div class="mb-[30px] space-y-[30px]">
        <div v-for="(input, index) in inputs" :key="index" class="space-y-5">
          <template v-if="input.type === 'toggle'">
            <div class="flex flex-row items-center justify-between">
              <div class="font-bold text-eonx-neutral-600">
                {{ input.label }}
              </div>
              <base-toggle v-model="input.preference.active" />
            </div>
            <div class="rounded-xl bg-gray-50 px-6 py-5">
              <div
                class="text-sm text-eonx-neutral-600"
                :class="[!input.description && 'font-bold']"
              >
                {{ input.title }}
              </div>
              <div class="font-bold">
                {{ input.description }}
              </div>
              <div
                class="text-sm font-normal text-eonx-neutral-600"
                :class="[!input.description && 'mt-[5px]']"
              >
                {{ input.preference.text }}
              </div>
            </div>
          </template>

          <template v-else-if="input.type === 'checkbox-list'">
            <div class="font-bold text-eonx-neutral-600">
              {{ input.label }}
            </div>
            <div class="space-y-2.5 rounded-xl bg-gray-50 px-6 py-5">
              <div class="text-sm text-eonx-neutral-600">
                {{ input.description }}
              </div>
              <base-checkbox
                v-for="preference in input.list"
                :key="preference.type"
                v-model="preference.active"
              >
                <div>
                  {{ preference.title }}
                </div>
                <div class="text-sm font-normal text-eonx-neutral-600">
                  {{ preference.text }}
                </div>
              </base-checkbox>
            </div>
          </template>
        </div>
      </div>
      <div class="mt-auto">
        <base-button
          :disabled="
            isCommunicationPreferencesLoading ||
            !isCommunicationPreferencesChanged
          "
          class="mt-auto w-full"
          @click="save"
        >
          Save
        </base-button>
      </div>
    </div>
  </base-aside-page>
</template>
